<template>
  <div class="material">
    <div v-for="item in list" :key="item.id">
      <div v-if="item.selected" class="group">
        <div class="head">
          <div class="tt">{{ item.name }}</div>
          <div class="num">{{ item.imgList.length }}/{{ imgMax[item.id] }}</div>
        </div>
        <div v-if="item.remarks" class="notice">
          {{ item.remarks }}
        </div>
        <div class="cont">
          <van-uploader v-model="item.imgList" :max-size="5000 * 1024" :max-count="imgMax[item.id]" :after-read="(file, detail)=>faceAfterRead(file, detail, item)" :before-read="beforeRead" @oversize="onOversize" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <van-button
        round
        block
        type="info"
        color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
        style="margin-bottom: 20px; margin-top: 40px;"
        @click="onSubmit"
      >
        确定
      </van-button>
    </div>
  </div>
</template>
<script>
import { filesAnon } from '@/api/file'
import { getDemandMaterialByOderAndType, saveMaterial } from '@/api/product'
export default {
  data() {
    return {
      fileList: [],
      orderId: '',
      list: [],
      imgMax: {
        '10040': 2,
        '10050': 20,
        '10060': 1,
        '10070': 2,
        '10080': 2,
        '10090': 1,
        '10100': 10
      }
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId
    this.getDemandMaterialByOderAndType()
  },
  methods: {
    onOversize() {
      this.$toast('图片大小不能超过5mb！')
    },
    async faceAfterRead(e, detail, item) {
      console.log('---------------------------------------')
      console.log(e)
      console.log(detail)
      console.log(item)
      console.log('---------------------------------------')
      const param = new FormData() // 创建form对象
      param.append('file', e.file) // 通过append向form对象添加数据
      e.status = 'uploading'
      e.message = '上传中...'
      try {
        const res = await filesAnon(param)
        e.url = res.datas.url
        e.status = 'done'
      } catch {
        item.imgList.splice(detail, 1)
      }
    },
    beforeRead(e) {
      console.log(e, '---------------')
      const types = ['image/jpeg', 'image/jpg', 'image/png']
      const isImage = types.includes(e.type)
      if (!isImage) {
        this.$toast('只能上传图片!')
        return false
      } else {
        return true
      }
    },
    async getDemandMaterialByOderAndType() {
      const res = await getDemandMaterialByOderAndType({ orderId: this.orderId, type: 0 })
      res.datas.map(item => {
        item.imgList = []
      })
      this.list = res.datas
      console.log(res, 1)
    },
    onSubmit() {
      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i]
        if (item.selected && item.imgList.length === 0) {
          return this.$toast(`${item.name}必须上传至少一张照片`)
        }
      }
      this.$dialog.confirm({
        title: '',
        message: '材料真实性将决定您的初审结果，请确认材料真实有效性才能上传哦~'
      })
        .then(async() => {
          // on confirm
          const materialImgReqList = []
          this.list.map(item => {
            item.imgList = item.imgList.map(img => {
              materialImgReqList.push({
                imgName: img.url,
                materialName: item.name,
                orderId: this.orderId,
                remark: '',
                saleId: 0,
                sort: item.sort,
                sysDictId: item.id
              })
            })
          })
          const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
          await saveMaterial({ materialImgReqList })
          this.$store.dispatch('user/setMaterialSendStatus', true)
          loading.clear()
          this.$router.go(-1)
        })
        .catch(() => {
          // on cancel
        })
      console.log(this.list)
    }
  }
}
</script>
<style lang="scss" scoped>
.material {
  padding: 16px;
  .group {
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
     .tt {
        font-size: 14px;
        color: #333333;
      }
     .num {
        font-size: 14px;
        color: #CCCCCC;
      }
    }
  }
  .notice {
    font-size: 12px;
    color: #999999;
    padding: 0 0 10px 0;
  }
}
</style>
